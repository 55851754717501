import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import { getCourseNavigation } from "../../../../../store/courses"
import { useThemeUI } from "theme-ui"

import BarChart from "../../../../../components/bar-chart"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Small from "../../../../../components/small"
import Stack from "../../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  const context = useThemeUI()
  const { theme } = context

  const chartData = [
    { id: 15, name: "können", value: "69" },
    { id: 14, name: "sagen", value: "72" },
    { id: 13, name: "sprechen", value: "77" },
    { id: 12, name: "sollen", value: "77" },
    { id: 11, name: "lassen", value: "77" },
    { id: 10, name: "lieben", value: "81" },
    { id: 9, name: "müssen", value: "86" },
    { id: 8, name: "singen", value: "96" },
    { id: 7, name: "leben", value: "113" },
    { id: 6, name: "wollen", value: "134" },
    { id: 5, name: "gehen", value: "169" },
    { id: 4, name: "sehen", value: "177" },
    { id: 3, name: "kommen", value: "194" },
    { id: 2, name: "haben", value: "236" },
    { id: 1, name: "sein", value: "1767" },
  ]

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/handschrift"
        />
      }
    >
      <Seo title="Verben" />
      <Stack>
        <Heading as="h1" level={2}>
          Verben bei Hölderlin
        </Heading>
        <Paragraph>
          Was passiert also in dem Gedicht? In der 1. Strophe geht es um den
          Frühling, der jedes Jahr aufs Neue zurückkommt und Freude, Gesang und
          Lieder in das Leben der Menschen bringt. Alles ist lebendig: Der
          Frühling kommt, der Mensch wundert sich, die Worte streben, die Freude
          kehrt wieder, Gesang und Lieder machen sich festlich. Die 2. Strophe
          beschreibt, wie dadurch alles harmonisch wird.
        </Paragraph>
        <Stack space={6}>
          <BarChart
            height="500px"
            data={chartData}
            colors={theme.colors["chart"]}
            layout="horizontal"
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            isInteractive={false}
            enableGridY={false}
            margin={{ top: 0, right: 0, bottom: 0, left: 80 }}
            indexBy="name"
          />
          <Small color="whisper">
            Hölderlins 20 häufigste Verben, gezählt von Heike Gfrereis, Vera
            Hildebrandt und Roland Kamzelak für die Ausstellung ›Hölderlin,
            Celan und die Sprachen der Poesie‹. Daten aus dem Deutschen
            Literaturarchiv Marbach.
          </Small>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
